import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import AddUser from './AddUser';
import ViewUsers from './ViewUsers';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
}));


export default function Users() {
    const location = useLocation();
    const match = useRouteMatch()

    const classes = useStyles();

    return (

        <div className={classes.root}>

            <Paper className={classes.root}>
                <Tabs
                    value={location.pathname}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    centered
                >


                    <Tab icon={<PersonAddIcon />} label="Add User" component={Link}
                        to={`${match.url}`}
                        value={`${match.url}`} />

                    <Tab icon={<FormatListBulletedIcon />} label="View Users" component={Link}
                        to={`${match.url}/view-users`}
                        value={`${match.url}/view-users`} />

                </Tabs>
            </Paper>
            <Switch>
                <Route
                    exact path={`${match.url}`}>
                    <AddUser />
                </Route >

                <Route path={`${match.url}/view-users`}>
                    <ViewUsers />
                </Route>
            </Switch>
        </div >
    )
}


