import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    fontWeight: "bold",
    fontSize: "13.5px",
  },
});
export default function EditOptionModal(props) {
  const { isOpen = false, handleClose, data, editOption } = props;
  const [title, setTitle] = useState(null);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  console.log("option data title ==", data.title)

  const classes = useStyles();
  useEffect(() => {
    setTitle(data.title);
    setValue(data.value);
  }, [data.title, data.value]);
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Edit Option</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            style={{ minWidth: 400 }}
            spacing={2}
          >
            <Grid item>
              <TextField
                id="title"
                label="Title"
                fullWidth
                onChange={handleTitleChange}
                value={title}
              />
              <FormHelperText
                className={classes.root}
                error
                id="component-error-text"
              >
                {error}
              </FormHelperText>
            </Grid>
            <Grid item>
              <TextField
                id="value"
                label="Value"
                fullWidth
                onChange={handleValueChange}
                value={value}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button color="primary" onClick={handleEditOption}>
              Update
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleValueChange(event) {
    setValue(event.target.value);
  }

  async function handleEditOption() {
    if (!title) {
      setError("Title is required");
    } else {
      editOption({
        title,
        value,
        id: props.data.id,
        queryIndex: props.data.queryIndex,
      });
      handleClose();
    }
  }
}

EditOptionModal.propTypes = {
  handleClose: PropTypes.func,
};
