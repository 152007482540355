/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import SegmentList from "views/Segments";
import Query from "views/Segments/Query/index";
import Login from "views/Forms/login";
import SignUp from "views/Forms/signup";
import PaymentForm from "views/Payment/Form/index";
import Transactions from "views/Payment/Transactions/index";
import { Settings } from "@material-ui/icons";
import LeadReciptents from "./views/Settings/LeadsReciptents";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleMenu from "./components/Menu/Menu";
import EmailIcon from "@material-ui/icons/Email";
import AddUser from "./views/Forms/adduser";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Users from "./views/Users/Users";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // },

  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl"
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin"
  // },
  {
    path: "/segments",
    name: "Segments",
    icon: "content_paste",
    component: SegmentList,
    layout: "/admin",
  },
  {
    path: "/add-users",
    name: "Users",
    rtlName: "إخطارات",
    icon: PersonAddIcon,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Email Recipients",
    rtlName: "إخطارات",
    icon: "email",
    component: LeadReciptents,
    layout: "/admin",
  },
  // {
  //   path: "/create-questions",
  //   name: "Questions",
  //   icon: "content_paste",
  //   component: Query,
  //   layout: "/admin"
  // }
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "content_paste",
  //   component: Login,
  //   layout: "/admin"
  // },
  // {
  //   path: "/signup",
  //   name: "Sign up",
  //   icon: "content_paste",
  //   component: SignUp,
  //   layout: "/admin"
  // },
  // {
  //   path: "/payment",
  //   name: "Payment Form",
  //   icon: "content_paste",
  //   component: PaymentForm,
  //   layout: "/admin"
  // },
  // {
  //   path: "/transaction",
  //   name: "Transactions",
  //   icon: "content_paste",
  //   component: Transactions,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
