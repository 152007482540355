import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/Alert";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import env from "../../constants/enum";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    fontWeight: "bold",
    fontSize: "13.5px",
  },
}));

export default function SignIn() {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(4, "Must be more than 9 characters")
        .required("Required"),
    }),
    async onSubmit(values) {
      console.log(`Values: ${values}`);
      console.log(values.email);
      const body = {
        email: values.email,
        password: values.password,
      };

      try {
        let res = await axios.post(`${env.API_URL}/login`, body);

        if (res.status === 200) {
          const token = res.data.token;
          const userId = res.data.data.id;

          localStorage.setItem("token", token);
          localStorage.setItem("userId", userId);

          setSuccessMsg("Login success");
          setTimeout(function () {
            history.push("/admin/dashboard");
          }, 2000);
        }
      } catch (err) {
        console.log("error: ", err);
        setErrorMsg("Invalid credentials");
        setTimeout(function () {
          window.location.replace("/login");
        }, 2000);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {/* <form onSubmit={SignIn} className={classes.form} noValidate> */}
        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          {successMsg ? (
            <Alert severity="success" variant="filled">
              {" "}
              {successMsg}
            </Alert>
          ) : null}
          {errorMsg ? (
            <Alert severity="error" variant="filled">
              {errorMsg}
            </Alert>
          ) : null}
          <TextField
            // value={email}
            // onChange={e => setEmail(e.target.value)}
            value={formik.values.email}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />

          {formik.touched.email && formik.errors.email ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.email}
            </FormHelperText>
          ) : null}

          <TextField
            // value={password}
            // onChange={e => setPassword(e.target.value)}
            value={formik.values.password}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {formik.touched.password && formik.errors.password ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.password}
            </FormHelperText>
          ) : null}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

// export default withRouter(SignIn)
