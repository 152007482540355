import fetchApi from "../services/api";

export const getHiddenFields = async ({ segmentHookId }) => {
  try {
    const { data, status, message } = await fetchApi({
      url: `segments/hook/hidden-fields?segmentHookId=${segmentHookId}`,
      method: "GET",
    });

    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};

export const getHook = async (segmentId) => {
  try {
    const { status, data, message } = await fetchApi({
      url: `segments/hook?segmentId=${segmentId}`,
      method: "GET",
    });
    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err, message: "Failed to fetch hook" };
  }
};

export const getHeaders = async ({ segmentHookId }) => {
  try {
    const { data, status, message } = await fetchApi({
      url: `segments/hook/header?segmentHookId=${segmentHookId}`,
      method: "GET",
    });

    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};

export const deleteHeader = async ({ headerId }) => {
  try {
    console.log("Header id ", headerId);
    const { data, status, message } = await fetchApi({
      url: `segments/hook/header?id=${headerId}`,
      method: "DELETE",
    });
    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};

export const deleteHiddenField = async ({ hiddenFieldId }) => {
  try {
    const { data, status, message } = await fetchApi({
      url: `segments/hook/hidden-fields?id=${hiddenFieldId}`,
      method: "DELETE",
    });
    return { data, status, message };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const updateHeaders = async (updatedHookHeaders) => {
  try {
    const { data, status, message } = await fetchApi({
      url: "segments/hook/header",
      method: "POST",
      bodyData: { updatedHookHeaders: updatedHookHeaders },
    });
    console.log("headers ", updatedHookHeaders);

    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};

export const updateHiddenFields = async (updatedHookHiddenFields) => {
  try {
    const { data, status, message } = await fetchApi({
      url: "segments/hook/hidden-fields",
      method: "POST",
      bodyData: { updatedHookHiddenFields: updatedHookHiddenFields },
    });

    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};
