import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
}));


export default function AddUser() {
    let location = useLocation();
    let match = useRouteMatch()
    console.log("location", location)
    console.log("match", match)
    // const [value, setValue] = useState(0);
    // const handleChange = () => {
    //     setValue(1);
    // };

    // console.log("value", value)
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div className={classes.root}>
            {/* <div> */}

            <Paper className={classes.root}>
                <Tabs
                    value={location.pathname}
                    indicatorColor="primary"
                    textColor="primary">

                    <Tab icon={<PersonAddIcon />} label="Add User" component={Link}
                        to={`${match.url}`}
                        value={`${match.url}`} />

                    <Tab icon={<FormatListBulletedIcon />} label="View Users" component={Link}
                        to={`${match.url}/view-users`}
                        value={`${match.url}/view-users`} />

                </Tabs>
            </Paper>
            <Switch>
                <Route
                    exact path={`${match.url}`}>
                    <h1>1st tab</h1>
                </Route >

                <Route path={`${match.url}/view-users`}>
                    <h1>2nd tab</h1>
                </Route>
            </Switch>







            {/* --------------------- */}

            {/* </div> */}

            {/* <Grid style={{ paddingLeft: '1%', paddingTop: '7%' }} container spacing={3}>

                <Grid container justify="flex-start" spacing={isSmall ? 0 : 3}>

                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="firstname"
                            label="First Name"
                            name="firstname"
                            autoFocus
                        />


                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="lastname"
                            label="Last Name"
                            name="lastname"
                        />
                    </Grid>
                </Grid>


                <Grid container justify="flex-start" spacing={isSmall ? 0 : 3}>

                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"

                        />


                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="phoneno"
                            label="Phone"
                            name="phoneno"

                        />
                    </Grid>
                </Grid>

                <Grid container justify="flex-start" spacing={isSmall ? 0 : 3}>

                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"

                        />


                    </Grid>
                </Grid>

                <Grid container justify="flex-start" spacing={isSmall ? 0 : 3} >
                    <Grid item xs={12} md={4} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Add User
                       </Button>
                    </Grid>
                </Grid>
            </Grid> */}
        </div >
    )
}


