import fetchApi from "../services/api";

export const addOption = async (optionData) => {
  try {
    const { data, status, message } = await fetchApi({
      url: "options",
      method: "POST",
      bodyData: optionData,
    });
    return { data, status, message };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const updateOption = async (optionData) => {
  try {
    const { data, status, message } = await fetchApi({
      url: "options",
      method: "PUT",
      bodyData: optionData,
    });
    return { data, status, message };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const getOption = async ({ queryId }) => {
  try {
    const { data, status, message } = await fetchApi({
      url: `options?queryId=${queryId}`,
      method: "GET",
    });
    return { data, status, message };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const deleteOption = async ({ optionId }) => {
  try {
    const { data, status, message } = await fetchApi({
      url: `options?id=${optionId}`,
      method: "DELETE",
    });
    return { data, status, message };
  } catch (err) {
    return { status: false, error: err };
  }
};
