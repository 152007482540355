
import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Fab from "@material-ui/core/Fab";
// import DeleteIcon from "@material-ui/icons/Delete";
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIcon from '@material-ui/icons/Assignment';
import * as segmentApi from "../../api/segment";
import * as emailRecipientsApi from "../../api/emailRecipients";
import validator from 'validator'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
// import StyledTableCell from '@material-ui/core/StyledTableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    error: {
        fontWeight: "bold",
        fontSize: "13px",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    table: {
        minWidth: 200,
    }

}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.common.white,
        fontSize: 16,
        letterSpacing: '2px',
        textAlign: 'center'

    },
    body: {
        fontSize: 15,
        textAlign: 'center'

    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const LeadReciptents = () => {

    const [recipients, setRecipients] = useState([])
    const [email, setEmail] = useState('')
    const [searchSegments, setSearchSegments] = useState("")
    const [selected, setSelected] = useState('')
    const [selectedSegmentTitle, setSelectedSegmentTitle] = useState('')
    const [segments, setSegments] = useState([])
    const [allRecipients, setAllRecipients] = useState('')
    const [allSegments, setAllSegments] = useState([])
    const [updateUi, setUpdateUi] = useState(false)
    const [error, setError] = useState('')
    const [segmentError, setSegmentError] = useState('')
    const [emailError, setEmailError] = useState('')

    const classes = useStyles();

    var filterSegments = [];
    filterSegments = segments && segments.filter((item) => {
        return item.title[0].toLowerCase().includes(searchSegments.toLowerCase());
    });

    const validateEmail = () => {

        if (validator.isEmail(email)) {
            setEmailError('')
            return true;
        } else {
            setEmailError('Enter valid Email!')
            return false;
        }
    }



    async function getSegments() {
        var id = localStorage.getItem("userId");
        const { data, error, message } = await segmentApi.getAllSegments({ id });
        setSegments(data)
    }



    async function addEmail() {
        if (!email) {
            setError('Please type email')
            console.log("Please type email")

        }
        if (!selected) {
            setSegmentError('Please select Segment')
            console.log('Please select Segment')
        }

        if (email && validateEmail()) {
            console.log("valid")
            const { data, error, message } = await emailRecipientsApi.addEmail(
                { recipientEmail: email, segmentId: selected });
            setUpdateUi(!updateUi)
            setEmail('')
            setError('')
            setEmailError('')
            console.log("submit")
        }
    }

    async function getRecipients() {

        const segmentId = selected

        if (selected) {
            const getSegmentTitle = segments.find((segment) => (segment.id === selected))
            if (getSegmentTitle) {
                setSelectedSegmentTitle(getSegmentTitle.title)
                try {
                    const { data, error, message } = await emailRecipientsApi.getRecipients({ segmentId })
                    setRecipients(data || [])
                    setSegmentError('')
                }
                catch (error) { console.log("error: ", error) }

            }
        }
        else {

            try {
                const { data, error, message } = await emailRecipientsApi.getAllRecipients()


                var id = localStorage.getItem("userId");
                const { data: segments } = await segmentApi.getAllSegments({ id });
                console.log("Recipients ", data)
                const segmentTitlesAndRecipients = []
                data && data.map((recipient) => {
                    const segment = segments.find(segment => {
                        return segment.id === recipient.segmentId
                    })
                    if (segment) {
                        segmentTitlesAndRecipients.push({ ...recipient, segmentTitle: segment.title })
                    }
                }
                )
                setAllRecipients(segmentTitlesAndRecipients)
            }
            catch (error) {
                console.log("error: ", error)
            }
        }


    }
    console.log("email", email)

    async function deleteRecipient(id) {

        const { data, error, message } = await emailRecipientsApi.deleteRecipient({ id })

        setUpdateUi(!updateUi)
    }

    useEffect(() => {
        getSegments()
        getRecipients()

    }, [selected, updateUi])



    return (
        <div>
            <Card>
                <CardHeader color="success">
                    <h4 className={classes.cardTitleWhite}>Email Recipients</h4>
                </CardHeader>
                <CardBody style={{ marginTop: '5%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} >
                            <TextField style={{ marginRight: "20px" }} id="outlined-basic" label="Recipient Email" variant="outlined" size="small"
                                multiline
                                rowsMax={4}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    if (email != null) {
                                        setError('')
                                    }
                                }}
                                // onChange={(e) => (setEmail(e.target.value)) }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                            />


                            <span>  <FormHelperText
                                className={classes.error}
                                error
                                id="component-error-text"
                            >
                                {error}
                            </FormHelperText>
                            </span>
                            <span>  <FormHelperText
                                className={classes.error}
                                error
                                id="component-error-text"
                            >
                                {segmentError}
                            </FormHelperText>
                            </span>

                            <span>  <FormHelperText
                                className={classes.error}
                                error
                                id="component-error-text"
                            >
                                {emailError}
                            </FormHelperText>
                            </span>


                            <div style={{ marginTop: "10px" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    className={classes.button}
                                    startIcon={<AddCircleIcon />}
                                    onClick={addEmail}
                                >
                                    Add
                                 </Button>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8} >

                            <span style={{ marginRight: "20px", fontSize: '16px', fontFamily: 'system-ui' }}>(Segments) <AssignmentIcon /> </span>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                aria-setsize="large"
                                value={selected}
                                onChange={(e) => {
                                    setSelected(e.target.value)
                                }
                                }
                            >

                                <Input placeholder=' Search Segments' type='text'
                                    value={searchSegments}
                                    onChange={(e) => setSearchSegments(e.target.value)}>
                                </Input>

                                {filterSegments && filterSegments.map((segment, index) => (
                                    <MenuItem key={index} value={segment.id}>{segment.title}</MenuItem>))
                                }

                            </Select>


                        </Grid>


                        <Grid item xs={12} md={12} >

                            <div className="table">
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left">S.No</StyledTableCell>
                                                <StyledTableCell align="left">Recipient</StyledTableCell>
                                                <StyledTableCell align="left">Segment</StyledTableCell>
                                                <StyledTableCell align="left">Delete</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                selected ? recipients.map((recipient, index) => (

                                                    < StyledTableRow key={recipient.name} >

                                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                                        <StyledTableCell align="left" > {recipient.email}</StyledTableCell>
                                                        <StyledTableCell align="left">{selectedSegmentTitle}</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {<DeleteIcon
                                                                variant="round"
                                                                size="small"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    deleteRecipient(recipient.id)
                                                                }}
                                                            />}</StyledTableCell>
                                                    </StyledTableRow>
                                                )) : allRecipients && allRecipients.map((recipient, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                                        <StyledTableCell align="left">{recipient.email}</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {recipient.segmentTitle}</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {<DeleteIcon
                                                                variant="round"
                                                                size="small"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    deleteRecipient(recipient.id)
                                                                }}
                                                            />}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>

                    </Grid>
                </CardBody>

            </Card >
        </div >


    );
}

export default LeadReciptents;