import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import * as userApi from "../../api/user";
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  error: {
    fontWeight: "bold",
    fontSize: "13px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  table: {
    minWidth: 100,
    marginTop: "20px",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    fontSize: 16,
    letterSpacing: "2px",
    textAlign: "center",
  },
  body: {
    fontSize: 15,
    textAlign: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ViewUsers = () => {
  const [users, setUsers] = useState([]);
  const [allRecipients, setAllRecipients] = useState("");
  const [updateUi, setUpdateUi] = useState(false);
  const [loader, setLoader] = useState(true);

  const classes = useStyles();
  async function getUsers(userId) {
    const allUsers = await userApi.getWorkSpaceUsers({ userId });
    console.log("allUsers", allUsers);
    setUsers(allUsers.data);
    setLoader(false);
  }

  async function deleteWorkspaceUser(accessUserId) {
    const ownerUserId = localStorage.getItem("userId");
    const { data, error, message } = await userApi.deleteWorkspaceUser({
      accessUserId,
      ownerUserId,
    });

    setUpdateUi(!updateUi);
  }

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    getUsers(userId);
  }, [updateUi]);
  return (
    <div className="table">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">User</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <div
              style={{
                position: "relative",
                left: "50%",
                right: "50%",
                display: "table-cell",
                padding: "20px 0 0 0",
              }}
              className="loader"
            >
              <ClipLoader size={45} margin={2} radius={40} color={"#0cb4c9"} />
            </div>
          ) : (
            <TableBody>
              {users.map((user, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">
                    {`${user.firstName} ${user.lastName}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">{user.email}</StyledTableCell>
                  <StyledTableCell align="left">
                    {
                      <DeleteIcon
                        variant="round"
                        size="small"
                        color="secondary"
                        onClick={() => {
                          deleteWorkspaceUser(user.id);
                        }}
                      />
                    }
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default ViewUsers;
