import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ClipLoader from "react-spinners/ClipLoader";
export default function CollapsibleTable(props) {
  const { tableHead = [], rows = [], Row, loader } = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {tableHead.map((value) => {
              return <TableCell align="center">{value}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loader ? (
            <div
              style={{
                position: "relative",
                left: "50%",
                right: "50%",
                display: "table-cell",
                padding: "20px 0 0 0",
              }}
            >
              <ClipLoader size={45} margin={2} radius={40} color={"#0cb4c9"} />
            </div>
          ) : (
            rows.map((row, index) => (
              <Row key={row.name} row={row} index={index} />
            ))
          )}
          {/* {rows.map((row, index) => (
            <Row key={row.name} row={row} index={index} />
          )) } */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  Row: PropTypes.func,
};
