import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
export default function EditSegmentModal(props) {
  const {
    isOpen = false,
    handleClose,
    updateSegment,
    getTableRowData,
    segmentData,
  } = props;

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [key, setKey] = useState(null);
  const [isActive, setIsActive] = useState(null);

  useEffect(
    () => {
      setTitle(segmentData.title);
      setDescription(segmentData.description);
      setKey(segmentData.key);
    },
    [segmentData.title],
    [segmentData.description]
  );

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Edit Segment</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            style={{ minWidth: 400 }}
            spacing={2}
          >
            <Grid item>
              <TextField
                id="title"
                label="Title"
                fullWidth
                onChange={handleTitleChange}
                value={title}
              />
            </Grid>
            <Grid item>
              <TextField
                id="description"
                label="Description"
                fullWidth
                onChange={handleDescriptionChange}
                value={description}
              />
            </Grid>
            <Grid item>
              <TextField
                id="key"
                label="Key"
                fullWidth
                onChange={handleKeyChange}
                value={key}
                disabled
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button color="primary" onClick={handleUpdateSegment}>
              UPDATE
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  function handleKeyChange(event) {
    setKey(event.target.value);
  }

  function handleIsActiveChange(event) {
    setIsActive(event.target.value);
  }

  async function handleUpdateSegment() {
    updateSegment({
      title,
      description,
      id: segmentData.id,
    });
    handleClose();
  }
}

EditSegmentModal.propTypes = {
  handleClose: PropTypes.func,
  getTableRowData: PropTypes.func,
};
