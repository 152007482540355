import fetchApi from "../services/api";
export const getUser = async ({ id }) => {
    try {
        const user = await fetchApi({
            url: `newusers?id=${id}`

        });

        return user;
    } catch (err) {
        console.log(err);
        return { status: false, error: err };
    }
};


export const editProfile = async (userData) => {
    try {
        const { status, data, message } = await fetchApi({
            url: "newusers",
            method: "PUT",
            bodyData: userData
        });
        return { status, data, message };
    } catch (err) {
        return { status: false, error: err };
    }
};

export const changePassword = async (userData) => {
    try {
        const { status, data, message } = await fetchApi({
            url: "newusers/change-password",
            method: "PUT",
            bodyData: userData
        });
        return { status, data, message };
    } catch (err) {
        return { status: false, error: err };
    }
};

export const addUserToWorkspace = async (userData) => {
    try {
        const { status, data, message } = await fetchApi({
            url: "newusers/workspace",
            method: "POST",
            bodyData: userData
        });
        return { status, data, message };
    } catch (err) {
        return { status: false, error: err };
    }
};

export const getWorkSpaceUsers = async ({ userId }) => {
    try {
        const users = await fetchApi({
            url: `newusers/workspace?userId=${userId}`
        });

        return users;
    } catch (err) {
        console.log(err);
        return { status: false, error: err };
    }
};
export const deleteWorkspaceUser = async ({ accessUserId, ownerUserId }) => {
    try {
        const { status, data, message } = await fetchApi({
            url: `newusers/workspace?accessUserId=${accessUserId}&ownerUserId=${ownerUserId}`,
            method: "DELETE",
        });

        return { status, data, message };
    } catch (err) {
        console.log(err);
        return { status: false, error: err };
    }
};