import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { DateRange } from 'react-date-range';
import { DefinedRange } from 'react-date-range';
import { addDays, subDays, startOfWeek, endOfWeek } from 'date-fns';

const useStyles = makeStyles({
    calender: {
        width: '100%'
    },
    dateranges: {
        width: '100%'
    }
});
export default function CalenderModal(props) {
    const { isOpen = false, handleClose, setCalDate } = props;

    const [state, setState] = useState([
        {
            startDate: subDays(new Date(), 29),
            endDate: new Date(),
            key: 'selection'
        }
    ]);


    const classes = useStyles();
    useEffect(() => {
        setCalDate(state)
    }, [state]);

    function handleSelect(item) {

        setState([item.selection])
        setCalDate(state)
        handleClose()

    }
    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Calender</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} sm={12} md={4}>
                            <DefinedRange
                                className={classes.dateranges}
                                onChange={handleSelect}
                                ranges={state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <DateRange
                                className={classes.calender}
                                onChange={handleSelect}
                                ranges={state}
                                months={1}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
            </Dialog>
        </div>
    );
}

CalenderModal.propTypes = {
    handleClose: PropTypes.func,
};
