import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import * as leadsApi from "../../../api/leads";
import Admin from "../../../layouts/Admin";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: 700,
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "700",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: 22,
    "& small": {
      color: "#777",
      fontSize: "70%",
      fontWeight: "700",
      lineHeight: "1",
    },
  },
  tableHeadCell: {
    fontWeight: 500,
    fontSize: 20,
  },
  tableBodyCell: {
    fontWeight: 400,
    cursor: "pointer",
    fontSize: 15,
  },
  extendedIcon: {
    marginRight: 2,
  },
};

const useStyles = makeStyles(styles);

export default function Leads(props) {
  let history = useHistory()
  const classes = useStyles();
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    fetchLeads();
  }, []);

  async function fetchLeads() {
    const {
      location: {
        state: { segmentId },
      },
    } = props;
    console.log("Segment Id ", segmentId);
    const { data: leadsData, error, status } = await leadsApi.getLeads({
      segmentId,
    });
    if (!error) {
      const leadsArray = leadsData.map((lead) => [
        lead.id,
        lead.createdAt,
        lead.segmentKey,
      ]);
      console.log("Leads array ", leadsArray);
      setLeads(leadsArray);
    }
  }

  function onRowClick(id) {
    const {
      location: {
        state: { segmentId },
      },
    } = props;
    props.history.push({
      pathname: "/lead-details",
      state: { leadId: id, segmentId },
    });
  }

  return (
    <Admin
      withoutLink={true}
      component={
        <CardBody xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 className={classes.cardTitleWhite}>Leads</h4>
                <Button
                  onClick={() => history.goBack()}
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBack />}
                >
                  Back
                 </Button>
              </div>


            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Id", "Submitted At", "Segment Key"]}
                onRowClick={onRowClick}
                tableData={leads}
                extraStyles={styles}
              />
            </CardBody>
          </Card>
        </CardBody>
      }
    />
  );
}
