import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  root: {
    fontWeight: "bold",
    fontSize: "13.5px"
  }
}));

export default function QuestionForm(props) {
  const classes = useStyles();
  const { onSubmit, addNewQuery } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sequence, setSequence] = useState("");
  const [attribute, setAttribute] = useState("root");
  const [queryTypeId, setQueryTypeId] = useState(1);
  const [error, setError] = useState("");
  const [createAlert, setCreateAlert] = useState(false);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [queryTypes, setQueryTypes] = useState([
    {
      id: 1,
      title: "Single"
    },
    {
      id: 2,
      title: "Multiple"
    },
    {
      id: 3,
      title: "Text Field"
    },
    { id: 4, title: "Operator Comment" },
    { id: 5, title: "Email" },
    { id: 6, title: "Number" },
    { id: 7, title: "Phone-Number" }
  ]);
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Create Questions
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="title"
            label="Title"
            fullWidth
            value={title}
            onChange={handleTitleChange}
          />
          <FormHelperText className={classes.root} error id="component-error-text">
            {error}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="sequence"
            label="Sequence"
            fullWidth
            value={sequence}
            onChange={handleSequenceChange}
          />
          <FormHelperText className={classes.root} error id="component-error-text">
            {error}
          </FormHelperText>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            id="attribute"
            label="Attribute"
            helperText="Value to be save against"
            fullWidth
            autoComplete="cc-csc"
            value={attribute}
            onChange={handleAttributeChange}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <InputLabel id="demo-simple-select-label">Answer Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleQuerySelectChange}
          >
            {queryTypes.map(queryType => (
              <MenuItem value={queryType.id}>{queryType.title}</MenuItem>
            ))}
          </Select>
        </Grid>
        {queryTypeId === 6 && (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="title"
                label="Min Value"
                fullWidth
                value={minValue}
                onChange={handleMinValueChange}
              />
              <FormHelperText className={classes.root} error id="component-error-text">
                {error}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="title"
                label="Max Value"
                fullWidth
                value={maxValue}
                onChange={handleMaxValueChange}
              />
              <FormHelperText className={classes.root} error id="component-error-text">
                {error}
              </FormHelperText>
            </Grid>
          </React.Fragment>
        )}

        <Grid item xs={12}>
          <Fab
            variant="extended"
            size="medium"
            aria-lable="create"
            color="primary"
            onClick={handleCreateQuery}
          >
            <AddIcon className={classes.extendedIcon} />
            Create
          </Fab>
        </Grid>
        <Snackbar open={createAlert} TransitionComponent={TransitionUp}>
          <Alert severity="success" variant="filled">
            Question created successfully
          </Alert>
        </Snackbar>
      </Grid>
    </React.Fragment>
  );

  function handleDescriptionChange(e) {
    setDescription(e.target.value);
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleSequenceChange(e) {
    setSequence(e.target.value);
  }

  function handleAttributeChange(e) {
    setAttribute(e.target.value);
  }

  function handleQuerySelectChange(e) {
    console.log("query select ", e.target.value);
    setQueryTypeId(e.target.value);
  }

  function handleMinValueChange(e) {
    setMinValue(e.target.value);
  }

  function handleMaxValueChange(e) {
    setMaxValue(e.target.value);
  }

  function clearFields() {
    setTitle("");
    setDescription("");
    setSequence("");
    setAttribute("");
  }

  function checkMinMax() {
    if (queryTypeId === 6 && minValue !== null && maxValue !== null) {
      if (Number(minValue) > Number(maxValue)) {
        console.log("Min ", minValue > maxValue);
        alert("Min value cannot be greater than max value");
        return false;
      } else if (Number(maxValue) < Number(minValue)) {
        alert("Max value cannot be less than min value");
        return false;
      } else {
        console.log("Inside set title");
        return true;
      }
    } else if (queryTypeId !== 6) {
      return true;
    } else {
      return false;
    }
  }

  function handleCreateQuery() {
    const isValidMinMax = checkMinMax();
    if (!isValidMinMax) {
      return;
    }
    console.log("Title ", title);
    if (!title || !sequence) {
      setError("These fields are required");
      console.log("error", error);
    } else {
      setError("");
      setCreateAlert(true);
      addNewQuery({
        title: queryTypeId === 6 ? `${title} Range(${minValue} - ${maxValue})` : title,
        description: "",
        sequence,
        attribute: "root",
        queryTypeId,
        minValue,
        maxValue
      });

      clearFields();
      setTimeout(() => {
        setCreateAlert(false);
      }, 3000);
    }
  }
}
