import fetchApi from "../services/api";

export const getLeads = async ({ segmentId }) => {
  try {
    const { status, data, error, message } = await fetchApi({
      url: `leads?segmentId=${segmentId}`,
      method: "GET",
    });

    return { status, data, message };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const countLeads = async ({ userId }) => {
  try {
    const { status, data, error, message } = await fetchApi({
      url: `leads/counts?userId=${userId}`,
      method: "GET",
    });

    return { status, data, message };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const countLeadsGraph = async ({ userId, startDate, endDate }) => {
  try {
    const { status, data, error, message } = await fetchApi({
      url: `leads/daily-leads?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
    });

    return { status, data, message };
  } catch (err) {
    return { status: false, error: err };
  }
};




export const getLeadResponses = async ({ leadId }) => {
  try {
    const { status, data, error, message } = await fetchApi({
      url: `leads/responses?leadId=${leadId}`,
      method: "GET",
    });

    return { status, data, error, message };
  } catch (err) {
    return { status: false, error: err };
  }
};
