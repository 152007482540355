import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormHelperText from '@material-ui/core/FormHelperText';
import crypto from "crypto-js";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    fontWeight: "bold",
    fontSize: '13.5px',
  }
})
export default function CreateSegmentModal(props) {
  const { isOpen = false, handleClose, addSegment } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [key, setKey] = useState(generateRandomHash());
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState('')

  function onClose() {
    setKey(generateRandomHash());
    handleClose();
  }

  const classes = useStyles()
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Create Segment</DialogTitle>
        <DialogContent>

          <Grid
            container
            direction="column"
            style={{ minWidth: 400 }}
            spacing={2}
          >
            <Grid item>
              <TextField
                id="title"
                required
                label="Title"
                fullWidth
                onChange={handleTitleChange}
              // onChange={(e)=>setTitle(e.target.value)}
              />
              <FormHelperText className={classes.root} error id="component-error-text">{error}</FormHelperText>

            </Grid>
            <Grid item>
              <TextField
                id="description"
                label="Description"
                fullWidth
                onChange={handleDescriptionChange}
              />
            </Grid>
            <Grid item>
              <TextField
                id="key"
                label="Key"
                fullWidth
                defaultValue={key}
                disabled
                onChange={handleKeyChange}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button color="primary" onClick={handleCreateSegment}>
              Create
            </Button>
          </DialogActions>
          {/* </form> */}
        </DialogContent>
      </Dialog>
    </div>
  );


  function handleTitleChange(event) {
    setTitle(event.target.value);
    if (title.length > 0) {
      setError("")
    }
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  function handleKeyChange(event) {
    setKey(event.target.value);
  }

  function handleIsActiveChange(event) {
    setIsActive(event.target.value);
  }

  function handleCreateSegment() {
    if (!title) {
      setError("Title is required")
    }
    else {
      addSegment({
        title,
        description,
        key,
        isActive,
        leads: 0,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
      handleClose();
    }
  }

  function generateRandomHash() {
    const randomNumber = Math.random().toString(36).substring(7);
    return randomNumber;
  }
}

CreateSegmentModal.propTypes = {
  handleClose: PropTypes.func,
};
