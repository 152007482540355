import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import * as segmentApi from "../../../api/segment";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import * as hookApi from "../../../api/hook";

function TabPanel(props) {
  const { children, value, index, other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function ApiHookModal(props) {
  const { isOpen, handleClose, segmentQuestions, segmentId, hookApiUrl, hookId } = props;

  const [attributesState, setAttributesState] = useState({});
  const [apiUrl, setApiUrl] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [hiddenFields, setHiddenFields] = useState([]);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // console.log("Props ", props);
  useEffect(() => {
    fetchAndUpdateHiddenFields();
    fetchAndUpdateHeaders();
    console.log("Hook api url props ", hookApiUrl);
    fetchAndUpdateHook();
  }, []);
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Configure Api Hook</DialogTitle>
        <DialogContent>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Parameters" {...allyProps(0)}></Tab>
              <Tab label="Header Configuration" {...allyProps(1)}></Tab>
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid
                container
                item
                direction="column"
                md={12}
                xs={12}
                style={{ marginBottom: 10 }}
              >
                <TextField
                  id="title"
                  label="API URL"
                  value={apiUrl}
                  onChange={event => setApiUrl(event.target.value)}
                />
              </Grid>
              {segmentQuestions
                .filter(question => question.queryTypeId != 4)
                .map(question => (
                  <Grid container direction="column" item md={6}>
                    <FormLabel style={{ fontWeight: "bold" }}>{question.title}</FormLabel>
                    <TextField
                      id={`${question.id}`}
                      placeholder="API field name"
                      value={
                        attributesState[`${question.id}`]
                          ? attributesState[`${question.id}`]
                          : question.attribute
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container style={{ marginTop: 20 }} md={12} sm={12} xs={12}>
              <Grid direction="column" md={12} sm={12} xs={12}>
                <Grid direction="row">
                  <h6>
                    Headers{" "}
                    <Fab
                      variant="extended"
                      size="small"
                      aria-lable="delete  "
                      color="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        addHeader();
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </Fab>
                  </h6>
                </Grid>
                {headers.map((header, index) => {
                  return (
                    <Grid
                      container
                      direction="row"
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: 8 }}
                    >
                      <TextField
                        id={index}
                        value={header.key}
                        placeholder="Key"
                        style={{ width: "40%", marginRight: "5%" }}
                        onChange={handleHeaderKeyChange}
                      />
                      <TextField
                        id={index}
                        value={header.value}
                        placeholder="Value"
                        style={{ width: "40%", marginRight: "5%" }}
                        onChange={handleHeaderValueChange}
                      />
                      <Fab
                        variant="extended"
                        size="small"
                        aria-lable="delete"
                        color="secondary"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          deleteHeader(header.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Fab>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid direction="column" md={12} sm={12} xs={12} style={{ marginTop: 15 }}>
                <h6>
                  Hidden Fields{" "}
                  <Fab
                    variant="extended"
                    size="small"
                    aria-lable="delete  "
                    color="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      addHiddenField();
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Fab>
                </h6>
              </Grid>
              {hiddenFields.map((hiddenField, index) => {
                return (
                  <Grid
                    container
                    direction="row"
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: 8 }}
                  >
                    <TextField
                      id={index}
                      value={hiddenField.key}
                      placeholder="Key"
                      style={{ width: "40%", marginRight: "5%" }}
                      onChange={handleHiddenFieldKeyChange}
                    />
                    <TextField
                      id={index}
                      value={hiddenField.value}
                      placeholder="Value"
                      style={{ width: "40%", marginRight: "5%" }}
                      onChange={handleHiddenFieldValueChange}
                    />
                    <Fab
                      variant="extended"
                      size="small"
                      aria-lable="delete"
                      color="secondary"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        deleteHiddenField(hiddenField.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </Fab>
                  </Grid>
                );
              })}
            </Grid>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              handleSave();
              handleClose();
            }}
          >
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function handleInputChange(event) {
    const { id, value } = event.target;
    setAttributesState({
      ...attributesState,
      [id]: value
    });
  }

  function handleHeaderKeyChange(event) {
    const { id, value } = event.target;
    let headersCopy = [...headers];
    headersCopy[id].key = value;
    setHeaders([...headersCopy]);
  }

  function handleHeaderValueChange(event) {
    const { id, value } = event.target;
    let headersCopy = [...headers];
    headersCopy[id].value = value;
    setHeaders([...headersCopy]);
  }

  function handleHiddenFieldKeyChange(event) {
    const { id, value } = event.target;
    let hiddenFieldsCopy = [...hiddenFields];
    hiddenFieldsCopy[id].key = value;
    setHiddenFields([...hiddenFieldsCopy]);
  }

  function handleHiddenFieldValueChange(event) {
    const { id, value } = event.target;
    let hiddenFieldsCopy = [...hiddenFields];
    hiddenFieldsCopy[id].value = value;
    setHiddenFields([...hiddenFieldsCopy]);
  }

  function addHeader() {
    setHeaders([...headers, { key: "", value: "", segmentHookId: segmentId }]);
  }

  function addHiddenField() {
    setHiddenFields([...hiddenFields, { key: "", value: "", segmentHookId: segmentId }]);
  }

  async function handleSave() {
    try {
      console.log("api url on save ", apiUrl);
      const { status, data, message } = await segmentApi.configureHook({
        apiUrl,
        questionsAttribute: attributesState,
        segmentId
      });
      await updateHeaders();
      await updateHiddenFields();
      if (status) {
        alert("Hook configured successfully");
      } else {
        alert("Failed to configure hook");
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchAndUpdateHiddenFields() {
    try {
      const { data, status } = await hookApi.getHiddenFields({
        segmentHookId: segmentId
      });
      console.log("Hidden fields fetched ", data);
      setHiddenFields([...data]);
    } catch (err) {
      console.log(err);
      setHiddenFields([]);
    }
  }

  async function fetchAndUpdateHeaders() {
    try {
      const { data, status } = await hookApi.getHeaders({
        segmentHookId: segmentId
      });
      console.log("H fetched ", data);
      setHeaders([...data]);
    } catch (err) {
      console.log(err);
      setHiddenFields([]);
    }
  }

  async function deleteHeader(headerId) {
    try {
      await hookApi.deleteHeader({ headerId });
      setHeaders(headers.filter(header => header.id !== headerId));
    } catch (err) {
      alert("Failed to deleted header");
      console.log(err);
    }
  }

  async function deleteHiddenField(hiddenFieldId) {
    try {
      await hookApi.deleteHiddenField({ hiddenFieldId });
      setHiddenFields(
        hiddenFields.filter(hiddenField => hiddenField.id !== hiddenFieldId)
      );
    } catch (err) {
      alert("Failed to deleted header");
      console.log(err);
    }
  }

  async function updateHeaders() {
    try {
      const { data, status, message } = await hookApi.updateHeaders(headers);
      console.log(message);
    } catch (err) {
      console.log(err);
      alert("Failed to updated hook");
    }
  }

  async function updateHiddenFields() {
    try {
      const { data, status, message } = await hookApi.updateHiddenFields(hiddenFields);
      console.log(message);
    } catch (err) {
      console.log(err);
      alert("Failed to updated hook hidden fields");
    }
  }

  async function fetchAndUpdateHook() {
    try {
      const { data, status, message } = await hookApi.getHook(segmentId);
      console.log("Hook data api url", data);
      setApiUrl(data.apiUrl);
    } catch (err) {
      console.log(err);
      alert("Failed to updated hook hidden fields");
    }
  }
}
