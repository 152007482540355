import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CollapseableTable from "components/CollapseableTable/index.js";
import CreateSegmentModal from "../../Modals/Segments/createSegmentModal";
import EditSegmentModal from "../../Modals/Segments/editSegmentModal";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: 700,
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "700",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: 22,
    "& small": {
      color: "#777",
      fontSize: "70%",
      fontWeight: "700",
      lineHeight: "1",
    },
  },
  tableHeadCell: {
    fontWeight: 600,
  },
  tableBodyCell: {
    fontWeight: 500,
    cursor: "pointer",
  },
  extendedIcon: {
    marginRight: 2,
  },
};

const segments = [
  [
    "82941597",
    "ace8752c-b66s-40fe-9e72-30361aebfb1e",
    "Success",
    "2.6$",
    "Card",
    "11/16/2020 6:59",
  ],
  [
    "82866008",
    "b65a46cf-b344-40fe-9e72-30361aebfb1e",
    "Success",
    "5.6$",
    "Card",
    "11/16/2020 6:59",
  ],
  [
    "82543708",
    "392a9e5a-a657-4d18-b11f-280d87dfe1eb",
    "Success",
    "2.6$",
    "Card",
    "11/16/2020 6:59",
  ],
  [
    "8298254",
    "05b0a5ae-7eea-4dff-b3f7-a1379caa1505",
    "Success",
    "2.6$",
    "Card",
    "11/16/2020 6:59",
  ],
];

const useStyles = makeStyles(styles);

function onRowClick() {
  alert("Table Row Clicked");
}

export default function SegmentList() {
  const classes = useStyles();

  //TODO: Rename function to open and close
  const [isCreateSegmentModalOpen, setIsCreateSegmentModalOpen] = useState(
    false
  );
  const [isEditSegmentModalOpen, setIsEditSegmentModalOpen] = useState(false);

  const [tableEditRow, setTableEditRow] = useState(0);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Transactions</h4>
            <p className={classes.cardCategoryWhite}>
              User payment transactions
            </p>
          </CardHeader>
          <CardBody>
            <EditSegmentModal
              isOpen={isEditSegmentModalOpen}
              handleClose={closeEditSegmentModal}
              updateSegment={updateSegment}
              getTableRowData={getTableRowData}
            />
            <Table
              tableHeaderColor="success"
              extraStyles={styles}
              onRowClick={onRowClick}
              tableHead={[
                "Id",
                "Order Id",
                "Status",
                "Amount",
                "Payment Method",
                "Created At",
              ]}
              tableData={segments}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  function openCreateSegmentModal() {
    setIsCreateSegmentModalOpen(true);
  }

  function closeCreateSegmentModal() {
    setIsCreateSegmentModalOpen();
  }

  function addSegment({
    title,
    description,
    key,
    isActive,
    leads,
    createdAt,
    updatedAt,
  }) {
    //TODO: Implement following
    //Call add segment api here
    segments.push([
      title,
      description,
      key,
      isActive,
      leads,
      createdAt,
      updatedAt,
    ]);
  }

  function openEditSegmentModal(rowIndex) {
    console.log("Edit segment executed");
    setTableEditRow(rowIndex);
    setIsEditSegmentModalOpen(true);
  }

  function closeEditSegmentModal() {
    setIsEditSegmentModalOpen(false);
  }

  function getTableRowData() {
    const selectedSegment = segments[tableEditRow];

    return {
      title: selectedSegment[0],
      description: selectedSegment[1],
      key: selectedSegment[3],
      isActive: selectedSegment[4],
    };
  }
  function updateSegment() {
    //TODO: Call the API to update segment here
  }
}
