import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import env from "../../constants/enum";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    fontWeight: "bold",
    fontSize: "13.5px",
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  // const [firstName, setFirstName] = useState()
  // const [lastName, setLastName] = useState()
  // const [email, setEmail] = useState()
  // const [phoneNo, setPhoneNo] = useState()
  // const [address, setAddress] = useState()
  // const [password, setPassword] = useState()
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      phoneno: "",
      address: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstname: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      lastname: Yup.string().required("Required"),
      phoneno: Yup.number().required("Required"),
      address: Yup.string("undefined")
        .min(5, "Too Short!")
        .max(50, "Too Long!"),
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .required("Required")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password does not match"
          ),
        }),
    }),
    async onSubmit(values) {
      const body = {
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        phoneNo: values.phoneno,
        address: values.address,
        password: values.password,
      };
      console.log(body);

      try {
        const res = await axios.post(`${env.API_URL}/newusers`, body);
        const { token, user } = res.data;

        if (res.status === 200 && res.data.status) {
          setSuccessMsg("User registered successfully");
          setTimeout(function () {
            window.location.replace("/login");
          }, 2000);
        } else {
          setSuccessMsg(null);
          setErrorMsg("User already exists");
          setTimeout(function () {
            window.location.replace("/signup");
          }, 2000);
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <Container Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>

        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          <TextField
            value={formik.values.email}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {formik.touched.email || formik.values.email && formik.errors.email ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.email}
            </FormHelperText>
          ) : null}
          <TextField
            value={formik.values.firstname}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            autoComplete="firstname"

          />
          {formik.touched.firstname || formik.values.firstname && formik.errors.firstname ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.firstname}
            </FormHelperText>
          ) : null}
          <TextField
            value={formik.values.lastname}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="lastname"

          />
          {formik.touched.lastname || formik.values.lastname && formik.errors.lastname ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.lastname}
            </FormHelperText>
          ) : null}
          <TextField
            value={formik.values.phoneno}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            id="phoneno"
            label="Phone No"
            name="phoneno"
            type="number"
            autoComplete="phoneno"

          />
          {formik.touched.phoneno || formik.values.phoneno && formik.errors.phoneno ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.phoneno}
            </FormHelperText>
          ) : null}
          <TextField
            // value={password}
            // onChange={e => setPassword(e.target.value)}
            value={formik.values.password}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {formik.touched.password || formik.values.password && formik.errors.password ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.password}
            </FormHelperText>
          ) : null}
          <TextField
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
          />
          {formik.touched.confirmPassword || formik.values.confirmPassword && formik.errors.confirmPassword ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.confirmPassword}
            </FormHelperText>
          ) : null}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                {"Go to Sign In"}
              </Link>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={successMsg || errorMsg}
          autoHideDuration={1000}
          onClose={false}
          TransitionComponent={TransitionUp}
        >
          {successMsg ? (
            <Alert onClose={false} severity="success" variant="filled">
              {successMsg}
            </Alert>
          ) : (
            <Alert onClose={false} severity="error" variant="filled">
              {errorMsg}
            </Alert>
          )}
        </Snackbar>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
