import React, { useState } from 'react'
import { makeStyles, rgbToHex } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as userApi from "../../api/user";
import Slide from '@material-ui/core/Slide';
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
        maxHeight: '100%',
        [theme.breakpoints.down('sm')]: {
            height: "100%"
        }

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    error: {
        fontWeight: '500',
        fontSize: "13.5px",
        marginTop: '0px'
    },
    textField: {

        textAlign: 'center',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",

    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));


const AddUser = () => {
    const classes = useStyles();
    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [valid, setValid] = useState(false)
    const [slideIn, setSlideIn] = useState();
    const [slideDirection, setSlideDirection] = useState('down');


    const formik = useFormik({
        initialValues: {
            email: "",
            firstname: "",
            lastname: "",
            phoneno: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Invalid email address").required("Required"),
            firstname: Yup.string()
                .min(2, "Too Short!")
                .max(50, "Too Long!")
                .required("Required"),
            lastname: Yup.string().required("Required"),
            phoneno: Yup.number().required("Required"),
            password: Yup.string().required("Required"),
            confirmPassword: Yup.string()
                .required("Required")
                .when("password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Password does not match"
                    ),
                }),
        }),
        async onSubmit(values) {
            setSlideIn(false)
            const ownerId = localStorage.getItem('userId')
            const body = {
                ownerUserId: ownerId,
                accessUserFirstName: values.firstname,
                accessUserLastName: values.lastname,
                accessUserEmail: values.email,
                accessUserPhoneNo: values.phoneno,
                accessUserPassword: values.password,
            };
            try {

                const response = await userApi.addUserToWorkspace(body)
                console.log("response", response)
                if (response.data) {
                    setSlideIn(true)
                    setSuccessMsg("User added successfully")
                    setErrorMsg("")
                }
                else {
                    setValid(true)
                    setSlideIn(true)
                    setErrorMsg("Email already exists")
                    setSuccessMsg("")

                }
                setTimeout(function () { setSlideIn(false) }, 3000);
            }
            catch (err) {
                console.log("error:", err)
            }

        }
    })

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div className={classes.root}  >
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Grid container>
                    <Grid container justify='center'>


                        <Grid style={{ display: !slideIn ? 'none' : 'block' }} item xs={12} sm={6} md={4}>
                            <Slide in={slideIn} direction={slideDirection}>
                                <div >
                                    {successMsg ? <Alert severity="success" variant="filled">
                                        {successMsg}
                                    </Alert> : null}

                                    {errorMsg ? <Alert severity="error" variant="filled">
                                        {errorMsg}
                                    </Alert> : null}

                                </div>
                            </Slide>
                        </Grid>

                    </Grid>
                    <Grid container justify="center" spacing={isSmall ? 0 : 3}>

                        <Grid item xs={12} sm={12} md={4} >
                            <TextField
                                className={classes.textField}
                                value={formik.values.firstname}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                id="firstname"
                                label="First Name"
                                name="firstname"
                                autoComplete="firstname"
                                autoFocus
                            />
                            {formik.touched.firstname || formik.values.firstname && formik.errors.firstname ? (
                                <FormHelperText
                                    className={classes.error}
                                    error
                                    id="component-error-text"
                                >
                                    {formik.errors.firstname}
                                </FormHelperText>
                            ) : null}
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextField
                                className={classes.textField}
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                id="lastname"
                                label="Last Name"
                                name="lastname"
                                autoComplete="lastname"
                            />
                            {formik.touched.lastname || formik.values.lastname && formik.errors.lastname ? (
                                <FormHelperText
                                    className={classes.error}
                                    error
                                    id="component-error-text"
                                >
                                    {formik.errors.lastname}
                                </FormHelperText>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={isSmall ? 0 : 3} >

                        <Grid item xs={12} sm={12} md={4} >

                            <TextField
                                className={classes.textField}
                                value={formik.values.email}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    if (errorMsg) {
                                        setValid(false)
                                    }
                                }}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                error={valid}

                            />
                            {formik.touched.email || formik.values.email && formik.errors.email ? (
                                <FormHelperText
                                    className={classes.error}
                                    error
                                    id="component-error-text"
                                >
                                    {formik.errors.email}
                                </FormHelperText>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} >
                            <TextField
                                className={classes.textField}
                                value={formik.values.phoneno}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                id="phoneno"
                                label="Phone No"
                                name="phoneno"
                                type="number"
                                autoComplete="phoneno"
                            />
                            {formik.touched.phoneno || formik.values.phoneno && formik.errors.phoneno ? (
                                <FormHelperText
                                    className={classes.error}
                                    error
                                    id="component-error-text"
                                >
                                    {formik.errors.phoneno}
                                </FormHelperText>
                            ) : null}
                        </Grid>

                    </Grid>

                    <Grid container justify="center" spacing={isSmall ? 0 : 3}>

                        <Grid item xs={12} sm={12} md={4} >
                            <TextField
                                className={classes.textField}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            {formik.touched.password || formik.values.password && formik.errors.password ? (
                                <FormHelperText
                                    className={classes.error}
                                    error
                                    id="component-error-text"
                                >
                                    {formik.errors.password}
                                </FormHelperText>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} >
                            <TextField
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="current-password"
                            />
                            {formik.touched.confirmPassword || formik.values.confirmPassword && formik.errors.confirmPassword ? (
                                <FormHelperText
                                    className={classes.error}
                                    error
                                    id="component-error-text"
                                >
                                    {formik.errors.confirmPassword}
                                </FormHelperText>
                            ) : null}
                        </Grid>

                    </Grid>

                    <Grid container justify="center" spacing={isSmall ? 0 : 3} >
                        <Grid style={{ textAlign: "center" }} item xs={12} sm={12} md={4} >

                            <Button style={{ marginTop: '25px' }} variant="contained" color="primary" type='submit'>Add User</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div >
    )
}

export default AddUser
