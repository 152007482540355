// const STAGING_ENVIRONMENT = {
//   API_URL: "http://localhost:5000",
// };

const STAGING_ENVIRONMENT = {
  API_URL: "https://api.chatbotapp.us",
};

const PRODUCTION_ENVIRONMENT = {
  API_URL: "",
};

export default STAGING_ENVIRONMENT;
