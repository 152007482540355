import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/Alert";
import { Redirect, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import env from "../../constants/enum";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    fontWeight: "bold",
    fontSize: "13.5px",
  },
}));

const ResetPassword = () => {
  const history = useHistory();
  const params = useParams();
  const token = params.token;
  console.log("token", token);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .required("Required")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password does not match",
          ),
        }),
    }),
    async onSubmit({ password, confirmPassword }) {
      try {
        const body = {
          password,
          confirmPassword,
          token,
        };

        let response = await axios.post(
          `${env.API_URL}/auth/reset-password`,
          body,
        );
        if (response.data.status) {
          setSuccessMsg(response.data.message);
          setTimeout(() => {
            setSuccessMsg("");
            history.push("/login");
          }, 3000);
        } else if (response.status) {
          setErrorMsg(
            "Link expired. Please try the reset password process again.",
          );
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }

        console.log(body);
      } catch (err) {
        console.log("Error:", err);
      }
    },
  });
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          {successMsg ? (
            <Alert severity="success" variant="filled">
              {successMsg}
            </Alert>
          ) : null}
          {errorMsg ? (
            <Alert severity="error" variant="filled">
              {errorMsg}
            </Alert>
          ) : null}

          <TextField
            value={formik.values.password}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {formik.touched.password ||
          (formik.values.password && formik.errors.password) ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.password}
            </FormHelperText>
          ) : null}
          <TextField
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
          />
          {formik.touched.confirmPassword ||
          (formik.values.confirmPassword && formik.errors.confirmPassword) ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.confirmPassword}
            </FormHelperText>
          ) : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
export default ResetPassword;
