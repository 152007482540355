import fetchApi from "../services/api";

export const addQuery = async (queryData) => {
  try {
    const { data, status, message } = await fetchApi({
      url: "queries",
      method: "POST",
      bodyData: queryData,
    });
    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};


export const replaceQuerySequence = async (replaceSequence) => {
  try {
    const { data, status, message } = await fetchApi({
      url: "queries/replace-sequence",
      method: "PUT",
      bodyData: replaceSequence,
    });
    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};


export const updateQuery = async (updatedQuery) => {
  try {
    const { data, status, message } = await fetchApi({
      url: "queries",
      method: "PUT",
      bodyData: updatedQuery,
    });
    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};

export const getQuery = async ({ segmentId, queryId }) => {
  try {
    const { data, status, message } = await fetchApi({
      url: `queries?segmentId=${segmentId}`,
      method: "GET",
    });

    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};

export const deleteQuery = async ({ queryId }) => {
  try {
    const { data, status, message } = await fetchApi({
      url: `queries?id=${queryId}`,
      method: "DELETE",
    });
    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};
