import React, { useState, useEffect } from "react";
import CollapseableTable from "../../../components/CollapseableTable";
import CreateQueryForm from "../../Forms/createQueryForm";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddAnswerModal from "../../Modals/Query/addAnswerModal";
import EditAnswerModal from "../../Modals/Options/editOptionModal";
import * as queryApi from "../../../api/query";
import * as optionApi from "../../../api/option";
import * as segmentApi from "../../../api/segment";
import EditOptionModal from "../../Modals/Options/editOptionModal";
import Admin from "../../../layouts/Admin";
import EditQueryModal from "views/Modals/Query/editQueryModel";
import Alert from "@material-ui/lab/Alert";
import ApiHookModal from "../../Modals/Segments/apiHookModal";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
const useRowStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  rowActionIcons: {
    marginLeft: 10
  }
}));

const tableHead = ["Title", "Answer Type", "Sequence", "Edit", "Delete", "Add Option"];

function createData(title, description, attribute, queryType, sequence) {
  return {
    title,
    description,
    attribute,
    queryType,
    sequence,

    options: [
      {
        title: "Express Delivery",
        value: "ED"
      },
      {
        title: "Economy Delivery",
        value: "Econ-Del"
      }
    ]
  };
}

function addOptions(queryOptions) {
  const { title, value, queryId } = queryOptions;
}

function openAddAnswerModal() {}

export default function Query(props) {
  const [isAddAnswerModalOpen, setIsAddAnswerModalOpen] = useState(false);
  const [isEditQueryModalOpen, setIsEditQueryModalOpen] = useState(false);
  const [isEditOptionModalOpen, setIsEditOptionModalOpen] = useState(false);
  const [isApiHookModalOpen, setIsApiHookModalOpen] = useState(false);
  const [editOptionValue, setEditOptionValue] = useState({
    title: "",
    value: "",
    id: "",
    queryIndex: 0
  });
  const [editQueryTitle, setEditQueryTitle] = useState("");
  const [queries, setQueries] = useState([]);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [queryRowIndex, setQueryRowIndex] = useState("");
  const [querySequence, setQuerySequence] = useState("");
  const [hideModal, setHideModal] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [activeError, setActiveError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [hookApiUrl, setHookApiUrl] = useState("");
  const [loader, setLoader] = useState(true);
  const [hookId, setHookId] = useState(null);
  const {
    location: { state }
  } = props;
  useEffect(() => {
    const {
      location: { state }
    } = props;
    getAllQueries(state.segmentId);
    fetchAndSetHookApiUrl();
  }, [update]);

  return (
    <Admin
      withoutLink={true}
      component={
        <React.Fragment>
          <Grid container xs={12} md={12} justify="flex-end">
            <Button
              onClick={() => {
                setIsApiHookModalOpen(true);
              }}
              style={{ backgroundColor: "blue", color: "#FFF" }}
              color="primary"
            >
              Configure Api Hook
            </Button>
          </Grid>

          <CreateQueryForm addNewQuery={addNewQuery} />
          <AddAnswerModal
            isOpen={isAddAnswerModalOpen}
            handleClose={closeAddAnswerModal}
            addAnswer={addAnswer}
          />

          <EditQueryModal
            isOpen={isEditQueryModalOpen}
            handleClose={handleEditQueryModalClose}
            data={{
              queryRowIndex,
              editQueryTitle,
              querySequence,
              hideModal,
              errorMsg,
              activeError
            }}
            updateQuery={updateQuery}
            replaceSequence={replaceSequence}
          />

          <EditOptionModal
            isOpen={isEditOptionModalOpen}
            data={editOptionValue}
            handleClose={handleEditOptionModalClose}
            editOption={editOption}
          />
          <ApiHookModal
            isOpen={isApiHookModalOpen}
            segmentId={state.segmentId}
            handleClose={() => {
              setIsApiHookModalOpen(false);
            }}
            segmentQuestions={queries}
            hookApiUrl={hookApiUrl}
            hookId={hookId}
          />

          <div style={{ marginTop: 30, textAlign: "center" }}>
            <CollapseableTable
              tableHead={tableHead}
              Row={Row}
              rows={queries}
              loader={loader}
            />
          </div>
        </React.Fragment>
      }
    />
  );

  async function fetchAndSetHookApiUrl() {
    const {
      location: { state }
    } = props;
    const { data, status } = await segmentApi.getHook(state.segmentId);
    console.log("Hook data", data);
    if (data) {
      setHookApiUrl(data.apiUrl || "");
      setHookId(data.id);
    }
  }

  function openAddAnswerModal(queryId, rowIndex) {
    setSelectedQueryId(queryId);
    setQueryRowIndex(rowIndex);
    setIsAddAnswerModalOpen(true);
  }

  function openEditQueryModal(rowIndex, title, sequence) {
    setActiveError(false);
    setHideModal("");
    setErrorMsg("");
    setQuerySequence(sequence);
    setQueryRowIndex(rowIndex);
    setEditQueryTitle(title);
    setIsEditQueryModalOpen(true);
  }

  function closeAddAnswerModal() {
    setIsAddAnswerModalOpen(false);
  }

  async function addAnswer(answerValue) {
    const { title, value } = answerValue;
    const { data, status, error } = await optionApi.addOption({
      title,
      value,
      queryId: selectedQueryId
    });
    if (!error) {
      let queriesCopy = queries;
      let optionsCopy = queriesCopy[queryRowIndex].options || [];
      queriesCopy[queryRowIndex].options = [...optionsCopy, data];
      setQueries([...queriesCopy]);
    }
  }

  function checkValidSequence(newSequence) {
    const sequenceExists = queries.some(query => query.sequence == newSequence);
    return sequenceExists;
  }

  async function addNewQuery(queryValues) {
    const {
      title,
      description,
      attribute,
      sequence,
      queryTypeId,
      minValue,
      maxValue
    } = queryValues;
    const {
      location: { state }
    } = props;
    //ADD QUERY API CALL HERE
    if (checkValidSequence(sequence)) {
      alert(`A question at sequence ${sequence} already exists`);
      return true;
    }
    const { data, status, error } = await queryApi.addQuery({
      title,
      description,
      attribute,
      sequence,
      queryTypeId,
      segmentId: state.segmentId,
      minValue,
      maxValue
    });
    if (!error) {
      const newValue = { ...data, options: [] };

      setQueries([...queries, newValue]);
    }
  }

  async function replaceSequence(replacedSequence) {
    try {
      const updatedSequence = await queryApi.replaceQuerySequence(replacedSequence);
      setUpdate(!update);
    } catch (err) {
      console.log("error is ", err);
    }
  }

  async function updateQuery(updatedQuery) {
    try {
      const {
        location: { state }
      } = props;

      const { data, status, error, message } = await queryApi.updateQuery({
        ...updatedQuery,
        segmentId: state.segmentId
      });
      console.log("status", status);
      if (status) {
        handleEditQueryModalClose();
        setUpdate(!update);
        setHideModal(false);
      } else {
        setActiveError(true);
        setErrorMsg(message);
        setHideModal(true);
        setEditQueryTitle(updatedQuery.title);
        setQuerySequence(updatedQuery.querySequence);
      }
    } catch (err) {
      console.log("error is ", err);
    }
  }

  async function deleteQuery(queryId, rowIndex) {
    const { data, status, error } = await queryApi.deleteQuery({ queryId });
    console.log("data delete", status);
    if (!error) {
      const copyQueries = queries.filter((query, index) => {
        if (!(index === rowIndex)) {
          return query;
        }
      });
      setQueries([...copyQueries]);
      setUpdate(!update);
    }
  }

  async function deleteOption(optionId, row) {
    const { status, error, message } = await optionApi.deleteOption({
      optionId
    });
    if (!error) {
    }
  }

  function handleEditOptionModalClose() {
    setIsEditOptionModalOpen(false);
  }
  function handleEditQueryModalClose() {
    setIsEditQueryModalOpen(false);
  }

  async function editOption(updatedData) {
    try {
      const { data, status, error } = await optionApi.updateOption(updatedData);

      const updatedQueries = queries.map((query, index) => {
        if (index === updatedData.queryIndex) {
          const options = queries[index];
          const updatedOptions = query.options.map(option => {
            if (option.id === updatedData.id) {
              return {
                id: option.id,
                title: updatedData.title,
                value: updatedData.value
              };
            } else {
              return option;
            }
          });
          let updatedQuery = { ...query };
          updatedQuery.options = updatedOptions;
          return updatedQuery;
        } else {
          return query;
        }
      });

      setQueries([...updatedQueries]);
    } catch (err) {
      console.log(err);
    }
  }
  async function getAllQueries(segmentId) {
    try {
      setLoader(true);
      let { data = [], status, error } = await queryApi.getQuery({
        segmentId
      });
      if (!error) {
        const optionPromises = await Promise.all(data.map(query => getOptions(query.id)));
        optionPromises.map((options, index) => {
          data[index].options = options;
        });
        data.sort((a, b) => a.sequence - b.sequence);
        setQueries(data);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("Error ", err);
      setQueries([]);
    }
  }

  async function getOptions(queryId) {
    const { data, status, error } = await optionApi.getOption({ queryId });

    if (error) {
      return [];
    }

    return data;
  }

  function Row(props) {
    const { row, tableHead, tableData, index } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const [options, setOptions] = useState([]);
    useEffect(() => {
      setOptions(row.options);
    }, []);

    function getQueryType(queryTypeId) {
      if (queryTypeId === 1) {
        return "Single";
      } else if (queryTypeId === 2) {
        return "Multiple";
      } else if (queryTypeId === 3) {
        return "Text Field";
      } else if (queryTypeId === 4) {
        return "Operator Comment";
      } else if (queryTypeId === 5) {
        return "Email";
      } else if (queryTypeId === 6) {
        return "Number";
      } else if (queryTypeId === 7) {
        return "Phone-Number";
      }
    }

    return (
      // {loader ? <ClipLoader /> : null}
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {!(row.queryTypeId === 3 || row.queryTypeId === 4) ? (
                open ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              ) : null}
            </IconButton>
          </TableCell>
          <TableCell align="center" component="th" scope="row">
            {row.title}
          </TableCell>
          <TableCell align="center">{getQueryType(row.queryTypeId)}</TableCell>
          <TableCell align="center">{row.sequence}</TableCell>
          <TableCell align="center">
            <Fab
              variant="extended"
              size="small"
              aria-lable="edit"
              color="secondary"
              onClick={() => {
                openEditQueryModal(row.id, row.title, row.sequence);
              }}
            >
              <EditIcon fontSize="small" />
            </Fab>
          </TableCell>
          <TableCell align="center">
            <Fab
              variant="extended"
              size="small"
              aria-lable="delete"
              color="secondary"
              style={{ marginLeft: 10 }}
              onClick={() => {
                deleteQuery(row.id, index);
              }}
            >
              <DeleteIcon fontSize="small" />
            </Fab>
          </TableCell>
          <TableCell align="center">
            {(row.queryTypeId === 1 || row.queryTypeId === 2) && (
              <Fab
                variant="extended"
                size="small"
                aria-lable="delete  "
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  openAddAnswerModal(row.id, index);
                }}
              >
                <AddIcon fontSize="small" />
              </Fab>
            )}
          </TableCell>
        </TableRow>
        {/* ------------------------------------------ */}
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Options
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Title</TableCell>
                      <TableCell align="center">Value</TableCell>
                      <TableCell align="center">Edit</TableCell>
                      <TableCell align="center">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {options.map(historyRow => (
                      <TableRow key={historyRow.title}>
                        <TableCell align="center">{historyRow.title}</TableCell>
                        <TableCell align="center">{historyRow.value}</TableCell>
                        <TableCell align="center">
                          <Fab
                            variant="extended"
                            size="small"
                            aria-lable="edit"
                            color="secondary"
                            onClick={() => {
                              setEditOptionValue({
                                title: historyRow.title,
                                value: historyRow.value,
                                id: historyRow.id,
                                queryIndex: index
                              });

                              setIsEditOptionModalOpen(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </Fab>
                        </TableCell>
                        <TableCell align="center">
                          <Fab
                            variant="extended"
                            size="small"
                            aria-lable="delete  "
                            color="secondary"
                            onClick={() => {
                              deleteOption(historyRow.id, row);
                              const filteredOptions = options.filter(
                                option => option.id !== historyRow.id
                              );
                              setOptions([...filteredOptions]);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}
