import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import crypto from "crypto-js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    fontWeight: "bold",
    fontSize: "13.5px",
  },
});
export default function AddAnswerModal(props) {
  const { isOpen = false, handleClose, addAnswer } = props;
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const classes = useStyles();
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Create Option</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            style={{ minWidth: 400 }}
            spacing={2}
          >
            <Grid item>
              <TextField
                id="title"
                label="Title"
                fullWidth
                onChange={handleTitleChange}
              />
              <FormHelperText
                className={classes.root}
                error
                id="component-error-text"
              >
                {error}
              </FormHelperText>
            </Grid>
            <Grid item>
              <TextField
                id="value"
                label="Value"
                fullWidth
                onChange={handleValueChange}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button color="primary" onClick={handleCreateSegment}>
              Create
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );

  function handleTitleChange(event) {
    setTitle(event.target.value);
    if (title.length > 0) {
      setError("");
    }
  }

  function handleValueChange(event) {
    setValue(event.target.value);
  }

  function handleCreateSegment() {
    if (!title) {
      setError("Title is required");
    } else {
      addAnswer({
        title,
        value,
      });
      handleClose();
    }
  }
}

AddAnswerModal.propTypes = {
  handleClose: PropTypes.func,
};
