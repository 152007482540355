import React from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { useState } from 'react';
import { useEffect } from 'react';
import * as userApi from "../../api/user"
import Alert from "@material-ui/lab/Alert";
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    textField: {

        marginBottom: '20px',
        textAlign: 'center',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",

    },
}));

const EditProfile = ({ user }) => {
    const classes = useStyles();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [slideIn, setSlideIn] = useState();
    const [slideDirection, setSlideDirection] = useState('down');

    async function editProfile() {

        setSlideIn(false)
        const id = localStorage.getItem('userId')
        let userData = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNo: phone,
            userId: id
        }
        const data = await userApi.editProfile(userData)
        console.log(data)
        console.log(data.message)
        if (data.data) {

            setSlideIn(true)
            setSuccessMsg(data.message)
            setErrorMsg('')
        }
        else {

            setSlideIn(true)
            setErrorMsg(data.message)
            setSuccessMsg('')
        }

        setTimeout(function () { setSlideIn(false) }, 3000);
    }


    useEffect(() => {
        console.log("Useeefect Edit profile")
        setFirstName(user && user.firstName)
        setLastName(user && user.lastName)
        setEmail(user && user.email)
        setPhone(user && user.phoneNo)

    }, [user])
    return (

        <div className={classes.root}>
            <Grid container >
                <Grid container justify='center'>


                    <Grid style={{ display: !slideIn ? 'none' : 'block', marginTop: '10px' }} item xs={12} sm={6} md={4}>
                        <Slide in={slideIn} direction={slideDirection}>
                            <div >

                                {successMsg ? <Alert severity="success" variant="filled">
                                    {successMsg}
                                </Alert> : null}

                                {errorMsg ? <Alert severity="error" variant="filled">
                                    {errorMsg}
                                </Alert> : null}

                            </div>
                        </Slide>
                    </Grid>

                </Grid>

                <Grid container justify="center">

                    <Grid item xs={12} sm={6} md={4} >

                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="firstname"
                            label="First Name"
                            name="firstname"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" >
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="lastname"
                            label="Last Name"
                            name="lastname"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />

                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={12} sm={6} md={4} >

                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            error={errorMsg ? true : false}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container justify="center" >
                    <Grid item xs={12} sm={6} md={4} >
                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            id="phone"
                            label="Phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />

                    </Grid>
                </Grid>
                <Grid container justify="center"  >
                    <Grid style={{ textAlign: "center" }} item xs={12} sm={6} md={4} >
                        <Button button="true" onClick={editProfile} color="success" >Save Changes</Button>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

export default EditProfile
