/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import Query from "../src/views/Segments/Query/index";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import Login from "./views/Forms/login";
import SignUp from "./views/Forms/signup";
import "bootstrap/dist/css/bootstrap.min.css";
import UserProfile from "views/UserProfile/UserProfile.js";
import Leads from "./views/Segments/Leads";
import LeadDetails from "./views/Segments/Leads/LeadDetails";
import ForgotPassword from "views/Forms/forgotpassword";
import ResetPassword from "views/Forms/resetpassword";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/rtl" component={RTL} />
      <Route path="/create-questions" component={Query} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/user" component={UserProfile}></Route>
      <Route path="/leads" component={Leads}></Route>
      <Route path="/lead-details" component={LeadDetails}></Route>
      <Route path="/forgot-password" component={ForgotPassword}></Route>
      <Route path="/reset-password/:token" component={ResetPassword}></Route>
      <Redirect from="/" to="/login" />
    </Switch>
  </Router>,
  document.getElementById("root"),
);
