import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

export default function EditQueryModal(props) {
    const {
        isOpen = false,
        handleClose,
        data,
        updateQuery,
        replaceSequence
    } = props;

    const [queryId, setQueryId] = useState("")
    const [title, setTitle] = useState("");
    const [querySequence, setQuerySequence] = useState("")
    const [hideModal, setHideModal] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [activeError, setActiveError] = useState(false)

    console.log("hideModal", hideModal)
    console.log("activeError", activeError)
    useEffect(() => {
        setQueryId(data.queryRowIndex)
        setTitle(data.editQueryTitle);
        setQuerySequence(data.querySequence)
        setHideModal(data.hideModal)
        setErrorMsg(data.errorMsg)
        setActiveError(data.activeError)

    }, [data]);

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Edit Question</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="column"
                        style={{ minWidth: 400 }}
                        spacing={2}
                    >
                        <Grid item>
                            <TextField
                                id="title"
                                label="Title"
                                fullWidth
                                onChange={handleTitleChange}
                                value={title}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="sequence"
                                label="Sequence"
                                fullWidth
                                helperText={errorMsg ? errorMsg : null}
                                error={activeError}
                                onChange={handleSequenceChange}
                                value={querySequence}
                            />
                        </Grid>
                        {hideModal && errorMsg ? <div><Grid item>
                            <Button color="primary" variant="contained" size="small" onClick={handleReplaceSequence}>
                                Replace
                            </Button>
                        </Grid></div> : null}
                    </Grid>
                    <DialogActions>
                        <Button color="primary" onClick={handleUpdateQuery} >
                            UPDATE
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );

    function handleTitleChange(event) {
        setTitle(event.target.value);
    }

    function handleSequenceChange(event) {
        setQuerySequence(event.target.value);
        if (errorMsg) {
            // // setHideModal("")
            // setHideModal(false)
            setActiveError(false)
            setErrorMsg("")
        }
    }


    async function handleUpdateQuery() {
        updateQuery({ queryId, title, querySequence })
    }

    async function handleReplaceSequence() {
        replaceSequence({ queryId, title, querySequence })
        handleClose()
    }
}

EditQueryModal.propTypes = {
    handleClose: PropTypes.func,
    replaceSequence: PropTypes.func,
};
