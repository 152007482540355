import fetchApi from "../services/api";

export const addEmail = async (Email) => {
    try {
        const { data, status, message } = await fetchApi({
            url: "recipients",
            method: "POST",
            bodyData: Email,
        });
        return { data, status, message };
    } catch (err) {
        return { status: false, error: err };
    }
};

export const getRecipients = async ({ segmentId }) => {
    try {
        const { data, status, message } = await fetchApi({
            url: `recipients?segmentId=${segmentId}`,
            method: "GET",
        });
        return { data, status, message };
    } catch (err) {
        return { status: false, error: err };
    }
};

export const getAllRecipients = async () => {
    try {
        const { data, status, message } = await fetchApi({
            url: `recipients/all`,
            method: "GET",
        });
        return { data, status, message };
    } catch (err) {
        return { status: false, error: err };
    }
};

export const deleteRecipient = async ({ id }) => {
    try {
        const { data, status, message } = await fetchApi({
            url: `recipients?id=${id}`,
            method: "DELETE",
        });
        return { data, status, message };
    } catch (err) {
        return { status: false, error: err };
    }
};