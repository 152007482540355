import env from "../constants/enum";

async function fetchApi({ url, method = "GET", bodyData }) {
  try {
    const response = await fetch(`${env.API_URL}/${url}`, {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body:
        method === "PUT" || method === "POST" ? JSON.stringify(bodyData) : null,
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
    return { status: false, data: null };
  }
}

export default fetchApi;
