import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/Alert";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import env from "../../constants/enum";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    fontWeight: "bold",
    fontSize: "13.5px",
  },
}));

const ForgotPassword = () => {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    async onSubmit({ email }) {
      try {
        const body = {
          email,
        };
        let response = await axios.post(
          `${env.API_URL}/auth/forgot-password`,
          body,
        );

        if (response.data.status) {
          setSuccessMsg("Link Sent. Check your Email");
          setTimeout(() => {
            setSuccessMsg("");
          }, 3000);
        }
        if (!response.data.status) {
          setErrorMsg("Email not exists");
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      } catch (err) {
        console.log("Error:", err);
      }
    },
  });
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          {successMsg ? (
            <Alert severity="success" variant="filled">
              {successMsg}
            </Alert>
          ) : null}
          {errorMsg ? (
            <Alert severity="error" variant="filled">
              {errorMsg}
            </Alert>
          ) : null}

          <TextField
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            style={{ marginTop: 30 }}
          />

          {formik.touched.email ||
          (formik.values.email && formik.errors.email) ? (
            <FormHelperText
              className={classes.root}
              error
              id="component-error-text"
            >
              {formik.errors.email}
            </FormHelperText>
          ) : null}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Request Reset Link
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
export default ForgotPassword;
