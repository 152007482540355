import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { NavLink } from "react-router-dom";

import { Switch } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    extraStyles = {},
    actions = [],
    onRowClick,
    onRowDelete,
    loader,
  } = props;

  useEffect(() => {}, [props]);
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                    style={
                      extraStyles.tableHeadCell ? extraStyles.tableHeadCell : {}
                    }
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}

        {loader ? (
          <div
            style={{
              position: "relative",
              left: "50%",
              right: "50%",
              display: "table-cell",
              padding: "20px 0 0 0",
            }}
            className="loader"
          >
            <ClipLoader size={45} margin={2} radius={40} color={"#0cb4c9"} />
          </div>
        ) : (
          <TableBody>
            {tableData &&
              tableData.map((prop, key) => {
                const mainRowId = prop[0];
                return (
                  <TableRow
                    key={key}
                    className={classes.tableBodyRow}
                    hover={true}
                  >
                    {prop.map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell}
                          key={key}
                          style={
                            extraStyles.tableBodyCell
                              ? extraStyles.tableBodyCell
                              : {}
                          }
                          onClick={
                            onRowClick
                              ? () => {
                                  onRowClick(mainRowId);
                                }
                              : null
                          }
                        >
                          {prop}
                        </TableCell>
                      );
                    })}
                    {actions.map((action, actionKey) => {
                      return (
                        <TableCell style={{ textAlign: "center" }}>
                          <span
                            className="material-icons"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              switch (action?.actionType) {
                                case "EDIT":
                                  return action.onClick(prop, key);
                                case "DELETE":
                                  return action.onClick(prop, key);

                                default:
                                  return action.onClick(prop, key);
                                  break;
                              }
                            }}
                          >
                            {action.icon}
                          </span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onRowClick: PropTypes.func,
  onRowDelete: PropTypes.func,
};
