import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from "@material-ui/core/TextField";
import FilledInput from "@material-ui/core/FilledInput";
import * as userApi from "../../api/user";
import Image from "../../logo.svg";
import { getUser } from "./../../api/user";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PersonIcon from "@material-ui/icons/Person";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {
  Link,
  Switch,
  useLocation,
  useRouteMatch,
  Route,
} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import Admin from "../../layouts/Admin";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textField: {
    marginBottom: "30px",
    textAlign: "center",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  inputLabel: {
    fontWeight: "500",
    color: "black",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const match = useRouteMatch();
  const [user, setUser] = useState({});

  function redirectToDashboard() {
    let path = "/admin/dashboard";
    history.push(path);
  }

  async function getUser() {
    const id = localStorage.getItem("userId");
    const user = await userApi.getUser({ id });

    setUser(user.data);
  }

  useEffect(() => {
    getUser();
  }, []);

  console.log("user profile", user);
  return (
    <Admin
      withoutLink={true}
      component={
        <div>
          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.root}>
                  <Tabs
                    value={location.pathname}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    centered
                  >
                    <Tab
                      icon={<PersonIcon />}
                      label="Profile"
                      component={Link}
                      to={`${match.url}`}
                      value={`${match.url}`}
                    />

                    <Tab
                      icon={<LockIcon />}
                      label="Change Password"
                      component={Link}
                      to={`${match.url}/change-password`}
                      value={`${match.url}/change-password`}
                    />
                  </Tabs>
                </Paper>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <div style={{ marginTop: 70 }}>
                <Switch>
                  <Route exact path={`${match.url}`}>
                    <EditProfile user={user} />
                  </Route>

                  <Route path={`${match.url}/change-password`}>
                    <ChangePassword />
                  </Route>
                </Switch>
              </div>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}
