import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import Button from "components/CustomButtons/Button.js";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as userApi from "../../api/user";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    marginBottom: "20px",
    textAlign: "center",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  error: {
    fontWeight: "500",
    fontSize: "13.5px",
  },
  button: {
    color: "white",
    backgroundColor: " #4caf50",
    padding: " 11px 25px",
    border: "none",
    borderRadius: "3px",
    fontSize: "13px",
    textTransform: " uppercase",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
}));
const ChangePassword = () => {
  const classes = useStyles();
  const [successMsg, setSuccessMsg] = useState("");
  const [displayAlert, setDisplayAlert] = useState(false);
  const [slideIn, setSlideIn] = useState();
  const [slideDirection, setSlideDirection] = useState("down");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .required("Required")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password does not match"
          ),
        }),
    }),
    async onSubmit(values) {
      const id = localStorage.getItem("userId");
      const body = {
        userId: id,
        password: values.password,
      };
      console.log(body);

      try {
        const result = await userApi.changePassword(body);
        console.log("result", result);
        setSuccessMsg(result.message);
        setDisplayAlert(true);
        formik.values.password = "";
        formik.values.confirmPassword = "";
      } catch (err) {
        console.log("error:", err);
      }
      setTimeout(function () {
        setDisplayAlert(false);
      }, 3000);
    },
  });
  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid container justify="center">
            <Grid item xs={12} sm={6} md={4}>
              {displayAlert ? (
                <div style={{ marginTop: "10px" }}>
                  <Alert severity="success" variant="filled">
                    {successMsg}
                  </Alert>
                </div>
              ) : null}
              <TextField
                className={classes.textField}
                type="password"
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
                id="password"
                label="New Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.touched.password ||
              (formik.values.password && formik.errors.password) ? (
                <FormHelperText
                  className={classes.error}
                  error
                  id="component-error-text"
                >
                  {formik.errors.password}
                </FormHelperText>
              ) : null}
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                size="small"
                type="password"
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />
              {formik.touched.confirmPassword ||
              (formik.values.confirmPassword &&
                formik.errors.confirmPassword) ? (
                <FormHelperText
                  className={classes.error}
                  error
                  id="component-error-text"
                >
                  {formik.errors.confirmPassword}
                </FormHelperText>
              ) : null}
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid style={{ textAlign: "center" }} item xs={12} sm={6} md={4}>
              <button type="submit" className={classes.button}>
                Change password
              </button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ChangePassword;
