import React, { useState, useEffect } from "react";
import CollapseableTable from "../../../components/CollapseableTable";
import CreateQueryForm from "../../Forms/createQueryForm";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AddAnswerModal from "../../Modals/Query/addAnswerModal";
import * as queryApi from "../../../api/query";
import * as leadsApi from "../../../api/leads";
import CardHeader from "components/Card/CardHeader.js";
import Admin from "../../../layouts/Admin";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';


const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  rowActionIcons: {
    marginLeft: 10,
  },
}));

const tableHead = ["Question Title", "Description", "Attribute", "Answer Type"];

function createData(title, description, attribute, queryType, sequence) {
  return {
    title,
    description,
    attribute,
    queryType,

    options: [
      {
        title: "Express Delivery",
        value: "ED",
      },
      {
        title: "Economy Delivery",
        value: "Econ-Del",
      },
    ],
  };
}

export default function Query(props) {
  let history = useHistory()
  const [isAddAnswerModalOpen, setIsAddAnswerModalOpen] = useState(false);
  const [queries, setQueries] = useState([]);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [queryRowIndex, setQueryRowIndex] = useState(null);

  useEffect(() => {
    const {
      location: {
        state: { segmentId },
      },
    } = props;
    getAllQueries(segmentId);
  }, []);

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
        fontWeight: 700,
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "700",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      fontSize: 22,
      "& small": {
        color: "#777",
        fontSize: "70%",
        fontWeight: "700",
        lineHeight: "1",
      },
    },
    tableHeadCell: {
      fontWeight: 500,
      fontSize: 20,
    },
    tableBodyCell: {
      fontWeight: 400,
      cursor: "pointer",
      fontSize: 15,
    },
    extendedIcon: {
      marginRight: 2,
    }
  };

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Admin
      withoutLink={true}
      component={
        <React.Fragment>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                border: "2px solid lightgrey",
                borderRadius: 10,
              }}
            >

              <CardHeader className={classes.leadDetails} color="info">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4 className={classes.cardTitleWhite}>Lead Details</h4>
                  <Button
                    onClick={() => history.goBack()}
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBack />}
                  >
                    Back
                 </Button>
                </div>
              </CardHeader>
              <div style={{ margin: '25px' }}>
                {
                  queries.map((question, i) => (
                    <div key={i} style={{ marginBottom: '20px' }} className="card">
                      <div className="card-body">
                        <h6 style={{ fontWeight: 'bold', textTransform: 'initial' }}> <span>{i + 1})</span> {question.title}</h6>
                        {question.options.map((option, j) => {
                          return option.title ? <div key={j}><p style={{ margin: '0px' }} ><b>{<ArrowRightIcon />}{option.title}</b></p></div> :
                            <div key={j}><b>{<ArrowRightIcon />}<p style={{ display: 'inline-block' }} >{option.value}</p></b></div>
                        }
                        )}

                      </div>
                    </div>
                  ))
                }

              </div>
            </div>
          </div>
        </React.Fragment>
      }
    />
  );

  async function getAllQueries(segmentId) {
    let { data, status, error } = await queryApi.getQuery({
      segmentId,
    });
    const {
      location: {
        state: { leadId },
      },
    } = props;
    if (!error) {
      for (let i = 0; i < data.length; i++) {
        const leadResponses = await getLeadResponses(leadId);
        const queryResponses = leadResponses.filter(
          (leadResponse) => leadResponse.questionId === data[i].id
        );
        console.log("Option fetched ", data[i].id);
        data[i].options = queryResponses;
      }
      console.log("Query final data ", data);
      setQueries(data);
      console.log("All questions", data)
    }
  }

  async function getLeadResponses(leadId) {
    console.log("Lead id ", leadId);
    const { data, status, error } = await leadsApi.getLeadResponses({
      leadId,
    });

    if (error) {
      console.log(error);
      return [];
    }
    console.log("Lead responses ", data);
    return data;
  }

  function Row(props) {
    const { row, tableHead, tableData, index } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    function getQueryType(queryTypeId) {
      if (queryTypeId === 1) {
        return "Single";
      } else if (queryTypeId === 2) {
        return "Multiple";
      } else if (queryTypeId === 3) {
        return "Text Field";
      }
    }

    // return (
    //   <React.Fragment>
    //     <div
    //       style={{
    //         flex: 1,
    //         backgroundColor: "red",
    //       }}
    //     ></div>
    //   </React.Fragment>
    // );
  }
}

{
  /* <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.title}
          </TableCell>
          <TableCell align="left">{row.description}</TableCell>
          <TableCell align="left">{row.attribute}</TableCell>
          <TableCell align="left">{getQueryType(row.queryTypeId)}</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Options
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Title</TableCell>
                      <TableCell align="center">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.options?.map((historyRow) => (
                      <TableRow key={historyRow.title}>
                        <TableCell align="center">{historyRow.title}</TableCell>
                        <TableCell align="center">{historyRow.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */
}
