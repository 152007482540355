import fetchApi from "../services/api";
export const addSegment = async (segmentData) => {
  try {
    const { status, data, message } = await fetchApi({
      url: "segments",
      method: "POST",
      bodyData: segmentData,
    });
    return { status, data, message };
  } catch (err) {
    console.log(err);
    return {
      status: false,
      message: "Failed to create segment",
      error: err,
    };
  }
};

export const getAllSegments = async ({ id }) => {
  try {
    const { status, data, message } = await fetchApi({
      // url: `segments?all=${true}`,
      url: `segments?id=${id}`,
    });
    console.log("data is", data);
    return { status, data, message };
  } catch (err) {
    return {
      status: false,
      message: "Failed to fetch segments",
      error: err,
    };
  }
};

export const deleteSegment = async ({ id }) => {
  try {
    const { status, data, message } = await fetchApi({
      url: `segments?id=${id}`,
      method: "DELETE",
    });

    return { status, data, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err };
  }
};

export const updateSegment = async (updatedSegment) => {
  try {
    const { status, data, message } = await fetchApi({
      url: "segments",
      method: "PUT",
      bodyData: updatedSegment,
    });
    return { status, data, message };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const configureHook = async (hookData) => {
  try {
    const { status, data, message } = await fetchApi({
      url: "segments/hook",
      method: "POST",
      bodyData: hookData,
    });
    return { status, data, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err, message: "Failed to configure hook" };
  }
};

export const getHook = async (segmentId) => {
  try {
    const { status, data, message } = await fetchApi({
      url: `segments/hook?segmentId=${segmentId}`,
      method: "GET",
    });
    return { data, status, message };
  } catch (err) {
    console.log(err);
    return { status: false, error: err, message: "Failed to fetch hook" };
  }
};
