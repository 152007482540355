import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Redirect } from "react-router-dom";
import { bugs, website, server } from "variables/general.js";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Login from "../Forms/login";
import SignIn from "./../Forms/signup";
import SignIn1 from "./../Forms/login";
import * as leadsApi from "../../api/leads";

import { DateRangePicker } from "react-date-range";
import { addDays, startOfWeek, endOfWeek } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { DefinedRange } from "react-date-range";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isTemplateExpression } from "typescript";
import { Button } from "@material-ui/core";
import CalenderModal from "./../Modals/Calender/Calender";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const useStyles = makeStyles(styles);
export default function Dashboard(props) {
  const classes = useStyles();
  const [totalLeads, setTotalLeads] = useState(null);
  const [leads, setLeads] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [calDate, setCalDate] = useState([]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const options = {
    chart: {
      height: 400,
      borderColor: "#EFF2F7",
      borderRadius: 8,
      borderWidth: 2,
      type: "line",
      shadow: true,
      spacingTop: 20,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Leads Graph",
      style: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "500",
        fontSize: "19px",
        margin: 10,
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: true,
      },
    },
    xAxis: {
      title: {
        text: "Dates",
      },
      categories: categories,
    },
    yAxis: {
      title: {
        text: "No of Leads",
        style: {
          fontSize: "12.5px",
        },
      },
    },
    series: [
      {
        name: "Leads",
        data: leads,
      },
    ],
  };

  const token = localStorage.getItem("token");
  if (!token) {
    props.history.push({
      pathname: "/login",
    });
  }

  function handleClose() {
    setShowCalender(false);
  }

  const countTotalLeads = async ({ userId }) => {
    const totalLeads = await leadsApi.countLeads({ userId });

    setTotalLeads(totalLeads.data);
  };

  const countLeadsGraph = async ({ userId, startDate, endDate }) => {
    console.log("startDate func", startDate);
    console.log("endDate func", endDate);
    const resultOfLeads = await leadsApi.countLeadsGraph({
      userId,
      startDate,
      endDate,
    });

    const { data } = resultOfLeads;
    const counts = data?.counts;

    let dates = [];
    let totalLeadCount = 0;
    dates = resultOfLeads.data && resultOfLeads.data.dateArray;
    let formattedDates =
      dates && dates.map((date) => moment(date).format("DD-MMM-YY"));
    setCategories(formattedDates && formattedDates);
    setLeads(resultOfLeads && resultOfLeads.data && resultOfLeads.data.counts);
    counts && counts.map((count) => (totalLeadCount += count));
    setTotalLeads(totalLeadCount);
  };

  useEffect(() => {
    var userId = localStorage.getItem("userId");
    let startDate = calDate && calDate[0] && calDate[0].startDate;
    let endDate = calDate && calDate[0] && calDate[0].endDate;

    console.log("startDate", startDate);
    console.log("endDate", endDate);
    countLeadsGraph({ userId, startDate, endDate });
  }, [calDate]);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Total Leads</p>
              <h3 className={classes.cardTitle}>
                {totalLeads ? totalLeads : "0"}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={9}>
          <CalenderModal
            isOpen={showCalender}
            setCalDate={setCalDate}
            handleClose={handleClose}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginBottom: "20px" }}
            startIcon={<CalendarTodayIcon />}
            onClick={() => setShowCalender(true)}
          >
            Calender
          </Button>
          <GridItem xs={12} sm={12} md={12}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </GridItem>
        </GridItem>
      </GridContainer>
    </div>
  );
}
