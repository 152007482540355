import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CreateSegmentModal from "../Modals/Segments/createSegmentModal";
import EditSegmentModal from "../Modals/Segments/editSegmentModal";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import * as segmentApi from "../../api/segment";
import { withRouter } from "react-router-dom";
import ConfirmationDialog from "../../components/Dialog/confirmationDialog";
import moment from "moment";
console.log("segmentApi", segmentApi);
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: 700,
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "700",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: 22,
    "& small": {
      color: "#777",
      fontSize: "70%",
      fontWeight: "700",
      lineHeight: "1",
    },
  },
  tableHeadCell: {
    fontWeight: 600,
    textAlign: "center",
  },
  tableBodyCell: {
    fontWeight: 500,
    cursor: "pointer",
    textAlign: "center",
  },
  extendedIcon: {
    marginRight: 2,
  },
};

const useStyles = makeStyles(styles);

function SegmentList(props) {
  const classes = useStyles();

  //TODO: Rename function to open and close
  const [isCreateSegmentModalOpen, setIsCreateSegmentModalOpen] = useState(
    false,
  );
  const [isEditSegmentModalOpen, setIsEditSegmentModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [tableEditRow, setTableEditRow] = useState(null);
  const [tableSelectedRow, setTableSelectedRow] = useState(null);
  const [segments, setSegments] = useState([]);
  const [editSegmentValue, setEditSegmentValue] = useState({
    title: "",
    description: "",
    id: "",
  });
  console.log("User segments", segments);
  const [loader, setLoader] = useState();
  const actions = [
    {
      icon: "chat",
      toolTip: "Leads",
      actionType: "LEADS",
      onClick: async (rowData, rowIndex) => {
        console.log("Clicked ", rowData);
        props.history.push({
          pathname: "/leads",
          state: { segmentId: rowData[0] },
        });
      },
    },
    {
      icon: "edit",
      toolTip: "Edit Segment",
      actionType: "EDIT",
      onClick: (rowData, rowIndex) => {
        openEditSegmentModal(rowIndex);
      },
    },
    {
      icon: "delete",
      toolTip: "Delete Segment",
      actionType: "DELETE",
      onClick: async (rowData, rowIndex) => {
        setTableSelectedRow({
          rowIndex,
          rowData,
        });
        setIsConfirmationOpen(true);
      },
    },
  ];
  var id = localStorage.getItem("userId");
  useEffect(() => {
    getSegments();
  }, [editSegmentValue]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Segments</h4>
            <p className={classes.cardCategoryWhite}>
              Generic segments for lead classification
            </p>
          </CardHeader>
          <CardBody>
            <Fab
              variant="extended"
              size="small"
              aria-lable="create"
              color="primary"
              onClick={openCreateSegmentModal}
            >
              <AddIcon size="small" className={classes.extendedIcon} />
              Create
            </Fab>
            <CreateSegmentModal
              isOpen={isCreateSegmentModalOpen}
              handleClose={closeCreateSegmentModal}
              addSegment={addSegment}
            />
            <EditSegmentModal
              isOpen={isEditSegmentModalOpen}
              handleClose={closeEditSegmentModal}
              updateSegment={updateSegment}
              getTableRowData={getTableRowData}
              segmentData={getTableRowData()}
            />
            <ConfirmationDialog
              isOpen={isConfirmationOpen}
              handleClose={handleIsConfirmationClose}
              title={"Confirm"}
              content={"Are you sure you want to delete ?"}
            />
            <Table
              tableHeaderColor="success"
              extraStyles={styles}
              actions={actions}
              loader={loader}
              onRowClick={onRowClick}
              tableHead={[
                "Id",
                "Title",
                "Description",
                "Key",
                "Created At",
                "Updated At",
                "Leads",
                "Edit",
                "Delete",
              ]}
              tableData={segments}
              onRowDelete={onRowDelete}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  function openCreateSegmentModal() {
    setIsCreateSegmentModalOpen(true);
  }

  function closeCreateSegmentModal() {
    setIsCreateSegmentModalOpen();
  }

  async function addSegment({
    title,
    description,
    key,
    isActive = 1,
    leads = 0,
  }) {
    //TODO: Implement following
    //Call add segment api here
    var userId = localStorage.getItem("userId");

    const { error, data } = await segmentApi.addSegment({
      title,
      description,
      key,
      isActive,
      leads,
      userId,
    });
    if (!error) {
      const segmentsCopy = segments;
      segmentsCopy.push([
        data.id,
        data.title,
        data.description,
        data.key,
        data.createdAt,
        data.updatedAt,
      ]);

      setSegments([...segmentsCopy]);
    }
  }

  function openEditSegmentModal(rowIndex) {
    setTableEditRow(rowIndex);
    setIsEditSegmentModalOpen(true);
  }

  function closeEditSegmentModal() {
    setIsEditSegmentModalOpen(false);
  }

  function getTableRowData() {
    console.log("All segments", segments);
    if (tableEditRow !== null) {
      const selectedSegment = segments[tableEditRow];

      return {
        id: selectedSegment[0],
        title: selectedSegment[1],
        description: selectedSegment[2],
        key: selectedSegment[3],
        isActive: selectedSegment[4],
      };
    }
    return {};
  }

  async function updateSegment(updatedSegment) {
    try {
      const { data, status, error } = await segmentApi.updateSegment(
        updatedSegment,
      );
      setEditSegmentValue({
        title: updatedSegment.title,
        description: updatedSegment.description,
        id: updatedSegment.id,
      });
    } catch (err) {
      console.log("error is ", err);
    }
  }
  async function getSegments() {
    setLoader(true);
    var id = localStorage.getItem("userId");

    const { data, error, message } = await segmentApi.getAllSegments({ id });

    if (!error) {
      const segments =
        data &&
        data.map((segment, index) => {
          return [
            segment.id,
            segment.title,
            segment.description,
            segment.key,
            moment(segment.createdAt).format("DD-MMM-YYYY"),
            moment(segment.updatedAt).format("DD-MMM-YYYY"),
          ];
        });

      setSegments(segments);
      setLoader(false);
    }
  }

  async function deleteSegment({ id, rowIndex }) {
    const { error } = await segmentApi.deleteSegment({ id });
    if (!error) {
      const segmentsCopy = segments;

      segmentsCopy.splice(rowIndex, 1);

      setSegments([...segmentsCopy]);
    }
  }

  function onRowClick(id) {
    props.history.push({
      pathname: "/create-questions",
      state: { segmentId: id },
    });
  }

  async function onRowDelete(rowData, rowIndex) {
    const { data, status, error } = await deleteSegment({
      id: rowData[0],
      rowIndex,
    });
  }

  async function handleIsConfirmationClose(onDeleteSegment) {
    const { rowData, rowIndex } = tableSelectedRow;
    if (onDeleteSegment)
      await deleteSegment({
        id: rowData[0],
        rowIndex,
      });
    setIsConfirmationOpen(false);
  }
}

export default withRouter(SegmentList);
